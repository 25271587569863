
import axios from "axios";
// import { gerar_token, somenteNumeros } from './DSi_basico'

var URL_DSI = sessionStorage['url']



export async function sendGetAxios(acao, url, token, body){
    
    //  isso daqui substitui o axios.get que da erro de cors e tal...

    const data = {
        acao          : acao,
        url           : url,
        access_token  : token,
        body          : body
    }  
    
    
    // envio para um arquivo php que intermediara o envio do get
    let url_to = URL_DSI+'getAxios.php'
    const res = await axios.post(url_to, data, {}); //o header ja estou passando em data  // const res = await axios.post(URL_DSI, data, header);
    
    console.log('\nretorno1:', res);

    let retorno = JSON.parse(res.data);

    console.log('\nretorno2:', retorno);

    return retorno
};




export default {
    sendGetAxios

};